.shape-blue {
  --shape-bg: #3f8eee;
  --shape-btn: #295ea1;
  --shap-icon: #8abbf6;
  --shap-border: #1b68c7;
}

.shape-pink {
  --shape-bg: #ec5ac8;
  --shape-btn: #a12b84;
  --shap-icon: #f19cdc;
  --shap-border: #c2229b;
}

.shape-purple {
  --shape-bg: #946cee;
  --shape-btn: #5e3ea6;
  --shap-icon: #b593f9;
  --shap-border: #6e3eca;
}

.shape-lightpurple {
  --shape-bg: #b466e9;
  --shape-btn: #7c37ab;
  --shap-icon: #d9a3fd;
  --shap-border: #904dbe;
}

.shape-yellow {
  --shape-bg: #edc643;
  --shape-btn: #fff;
  --shap-icon: #f5cd80;
  --shap-border: #c5912e;
}
.shape-darkyellow {
  --shape-bg: #f4b844;
  --shape-btn: #fff;
  --shap-icon: #f5cd80;
  --shap-border: #c5912e;
}

.shape-lightblue {
  --shape-bg: #67c9ed;
  --shape-btn: #fff;
  --shap-icon: #a6def3;
  --shap-border: #399cc1;
}
.shape-green {
  --shape-bg: #53b65a;
  --shape-btn: #fff;
  --shap-icon: #8adc90;
  --shap-border: #2b9732;
}

.shape-orange {
  --shape-bg: #f09541;
  --shape-btn: #fff;
  --shap-icon: #f8bf8a;
  --shap-border: #d2731b;
}

.shape-red {
  --shape-bg: #ec6869;
  --shape-btn: #fff;
  --shap-icon: #f39a9a;
  --shap-border: #c23b3c;
}

.model_list {
  @apply border-r overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100  min-w-[10rem] sm:min-w-[13rem] md:min-w-[17.5rem] max-w-[22rem] w-full border-r-light-gray pr-[0.875rem] sm:pr-[1.625rem];
}
.model_item {
  @apply border-b py-4 sm:py-5 border-b-creamy-white;
}
.model_item:last-child {
  @apply border-none;
}
.model_box {
  @apply w-full lg:w-[54rem] shadow-lg h-[calc(100vh-7rem)] flex gap-3 sm:gap-8 px-4 sm:px-6 py-4 sm:py-11 rounded-[1.25rem] bg-white;
}
.model_items_wrapper {
  @apply text-midnight-black text-sm sm:text-base cursor-pointer items-center flex gap-2 md:gap-[0.8125rem];
}

.active_arrow {
  @apply rotate-180;
}
.active_btn {
  @apply !bg-dark-green hover:!bg-dark-green hover:!border-dark-green !border-dark-green;
}
.active_btn svg {
  filter: invert(1) !important;
}
.model_radio_box {
  @apply w-4 md:w-5 h-4 md:h-5 bg-[var(--shape-bg)];
}
.main-shape {
  @apply flex bg-[var(--shape-bg)] rounded-md py-[0.3125rem] mb-3 pl-4 h-[2.8125rem]   pr-3 text-[12px] w-fit text-white;
}

.shape {
  @apply flex items-center;
}
.shape-icon svg {
  @apply rotate-180 w-5 h-5 z-[2] fill-[var(--shape-btn)];
}

.sub_model_box {
  @apply pl-4 flex gap-[6px] items-center;
}
.sub_model_turn_on {
  @apply border-l border-l-[var(--shap-border)] flex items-center whitespace-nowrap   pl-2 font-bold;
}
.dropdown_wrapper {
  @apply relative mx-auto w-fit;
}
.dropdown_btn {
  @apply !rounded-full !bg-[var(--shap-border)] text-white !border-[var(--shap-border)] hover:opacity-80 !h-4 !flex items-center !gap-[0.5rem] !min-h-8 !px-2;
}
.dropdown_icon svg {
  @apply rotate-180 w-4 h-4 fill-[var(--shape-btn)];
}
.dropdown_arrow svg {
  @apply w-3 h-3 fill-[var(--shap-icon)];
}

.dropdown_content {
  @apply p-4 rounded-lg z-[2]  bg-[var(--shap-border)]  w-fit absolute top-[50px] left-[-23px];
}
.dropdown_content:before {
  @apply w-5 h-5  rounded-full  bg-[var(--shap-border)]  content-[''] -translate-x-1/2 absolute top-[-7px] left-1/2;
}

.rounded_button {
  @apply bg-white text-center outline-none border-none text-[var(--shape-bg)] font-bold text-[12px] flex items-center justify-center rounded-full h-7 px-[0.3125rem] w-fit;
}
/* .rounded_text_btn{
  @apply px-[0.3125rem];
} */
.model_details {
  @apply w-full h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-200  scrollbar-track-gray-100;
}
.model_details .main-shape {
  @apply mb-0;
}
.model_details_wrapper {
  @apply !m-0 !p-0 flex items-center gap-1;
}

.model_details_width {
  @apply !my-[1.4rem] !w-full !border-none;
}

/* color select css  */

.radio-dark-blue {
  --radio-bg: #4d1fa7;
}

.radio-light-white {
  --radio-bg: #efefef;
}
.radio-red {
  --radio-bg: #fb1a1c;
}
.radio-orange {
  --radio-bg: #f9a32e;
}

.radio-yellow {
  --radio-bg: #fedf66;
}
.radio-dark-green {
  --radio-bg: #1d9d46;
}
.radio-green {
  --radio-bg: #23c356;
}
.radio-light-blue {
  --radio-bg: #06daff;
}
.radio-blue {
  --radio-bg: #1684ed;
}

.radio-purple {
  --radio-bg: #b558e8;
}

.select_color_Grid .dropdown_content {
  @apply grid grid-cols-5 items-center gap-y-[7px] gap-x-[4px] w-[7.5rem] left-[-2.6rem];
}
.selected_color_pallet {
  @apply w-3 h-3 rounded-full bg-[var(--radio-bg)] block relative;
}
.selected_color_pallet .radio-accent {
  @apply bg-[var(--radio-bg)];
}
.radio {
  @apply !w-3 !h-3 !bg-[var(--radio-bg)] !border-[var(--radio-bg)];
}

.radio:checked,
.radio[aria-checked='true'] {
  @apply !shadow-[0_0_0_1px_#fff];
}

/* color palette  */

/* select quantitiy grid */

.select_quantity_Grid .dropdown_content {
  @apply left-0 text-[var(--shap-icon)] font-light;
}
.select_quantity_Grid .dropdown_content .active {
  @apply text-white font-normal;
}

.select_quantity_number,
.select_word_name,
.select_control_name {
  font-style: normal;
}

/* select multiple grid  */

.select_multi_Grid .dropdown_content {
  @apply grid grid-cols-5 gap-1 w-[7rem];
}
.select_multi_Grid_item {
  @apply w-[10px] h-[10px] rounded-sm block bg-[var(--shape-btn)];
}
.select_multi_Grid_item.active {
  @apply bg-white;
}

/* select rotation   */

.select_rotation_icon svg {
  @apply w-[13px] h-[13px];
}
.select_rotation_Grid .dropdown_content {
  @apply grid grid-cols-2 gap-3 items-center left-[-6px] w-[4rem];
}
.select_rotation_Grid .dropdown_content .select_rotation_icon {
  @apply text-[var(--shap-icon)];
}
.select_rotation_Grid .dropdown_content .select_rotation_icon.active {
  @apply text-white;
}

/* select loader css  */

.select_loader_Grid .dropdown_content {
  @apply grid grid-cols-2 gap-2 items-center left-[0] w-[5rem];
}

.rotation_icon svg {
  @apply text-black;
}
.rotation_icon.active svg {
  @apply text-white;
}
.rotation_icon svg {
  @apply w-[14px] h-[14px];
}

.dropdown_content .rotation_icon svg {
  @apply w-[1.375rem] h-[1.375rem];
}

/* word filter  */
.word_content_wrapper {
  @apply rounded-lg grid grid-cols-2 w-20 justify-between gap-x-3 gap-y-2 border p-2 border-[var(--shap-icon)];
}
.select_word_name {
  @apply text-center text-[var(--shap-icon)];
  cursor: pointer;
}
.select_control_name {
  cursor: pointer;
}
.select_word_name.active,
.select_control_name.active {
  @apply text-white;
}
.select_word_Grid .dropdown_content {
  @apply left-[-30px];
}

.shape-grid-icon {
  -webkit-mask-image: url('../Images/grid-icon.svg');
  mask-image: url('../Images/grid-icon.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  @apply flex bg-[var(--shape-btn)] w-[13px] h-[13px] relative;
}
.shape-grid-icon:before {
  @apply content-[''] absolute w-[4px] h-[4px] bg-white;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.dropdown_content_remove_grid {
  @apply grid-cols-1 text-left;
}
.dropdown_content_remove_grid span {
  @apply whitespace-nowrap w-full  block font-bold mb-2;
}
.dropdown_content_remove_grid span:last-child {
  @apply mb-0;
}

/* hotkeys css */

.model_box_hotkey {
  @apply w-[19.3125rem] sm:absolute sm:right-44 py-4 sm:py-7 px-6 rounded-[1.25rem] bg-white;
}

.active_btn_hotkey {
  @apply !bg-black hover:!bg-black hover:!border-black !border-black;
}
.active_btn_hotkey svg {
  filter: invert(1) !important;
}

.custom-dropdown {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  background-color: #b09400;
}
.custom-dropdown-power {
  display: grid;
  padding: 8px;
  background-color: #691ff0;
  border-radius: 6px;
  border: 1px solid #691ff0;
}
.custom-dropdown-power-button {
  width: 40px;
  height: 15px;
  cursor: pointer;
  position: relative;
}

/* custom port */

.lls-port-selector {
  background-color: #1684ed;
}

.lls-port-selector__hub-wrapper {
  margin-bottom: 16px;
  margin-top: 30px;
  border: 2px solid black;
  padding: 20px 0;
  border-radius: 15px;
}

.lls-port-selector__hub {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.lls-port-selector__sensors {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sensor-port-pair {
  display: flex;
  align-items: center;
}

.sensor-port-pair__port-button {
  width: 25px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.sensor-port-pair__port-button.selected {
  background: black;
  color: rgb(0, 144, 245);
}

.lls-port-selector__multiple-actions {
  display: flex;
  gap: 12px;
}

.lls-port-selector__button {
  width: auto;
  min-width: 50px; /* Minimum width to prevent collapse */
  padding: 5px 10px; /* Adjust padding for comfortable click targets */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Ellipsis for overflow */
}

.display-none {
  display: none;
}

.lls-port-selector__button-multiple,
.lls-port-selector__button-all {
  width: 75px;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: black;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s ease;
  font-weight: 700;
  border: 2px solid black;
  border-radius: 5px;
}

.lls-port-selector__button-multiple.selected,
.lls-port-selector__button-all.selected {
  background: black;
  color: rgb(0, 144, 245);
}

.lls-port-selector__sensors--left .sensor-port-pair {
  border: 2px solid black;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.lls-port-selector__sensors--right .sensor-port-pair {
  border: 2px solid black;
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.zelos-renderer.CustomColorTheme-theme .blocklyHtmlInput {
  position: absolute;
  right: 0;
}

.blocklyFlyout {
  width: 250px !important; /* Set to de sired width */
}

.blocklyDropDownDiv{
  background-color: #EC5AC8;
}

.blocklyDropDownContentFieldAngleMainDiv {
  transition: transform 0.25s, opacity 0.25s;
  background-color: #b2248a !important;
  border-color: rgb(178, 36, 138);
  direction: ltr;
  display: flex;
  justify-content: center;
  opacity: 1;
  height: 100%;
  width: 125px;
}


.blocklyDropDownContentFieldAngleDiv{
  width: 100%;
}

.fieldAngleButton {
  width: 25px;
  height: 25px;
  border: solid 1px rgba(255, 255, 255, 0.6);
  background: transparent;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  line-height: 22px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.8;
  outline: none;
}

.my-custom-stating-svg{
  position: absolute !important;
}