@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html,
body {
  @apply font-inter;
}

.max-w-full-calc {
  max-width: calc(100% - 16px);
}

.blocklyFlyoutScrollbar[display='none'] {
  display: none;
}
/* Components  css  */

@import './model.css';
